import {FC, useCallback, useEffect, useMemo} from 'react';
import {useStore} from 'effector-react';
import {useLocation, Link} from 'react-router-dom';

import Modal, {showModal, hideModal} from '~/components/common/Modal';
import Icon, {Icons} from '~/components/icons';
import {$account} from '~/store/account';
import env from '~/config/appEnv';
import {Page} from '~/utils/getLink';
import {generateHotLink} from '~/utils/hotFunded';

import {MODAL_HOT_ADS_PAUSED_ID, HOT_PROTECTED_PAGES} from './constants';
import './styles.scss';

const ModalHotAdsPaused: FC = () => {
  const location = useLocation();
  const account = useStore($account);

  useEffect(() => {
    if (!env.REACT_APP_HOT_CAMPAIGN || env.REACT_APP_HOT_CAMPAIGN === '0') {
      return;
    }

    if (!account) {
      return;
    }

    if (!account.hotPausedSince) {
      return;
    }

    const isUrlProtected = (HOT_PROTECTED_PAGES as string[]).some((ptrn) =>
      location.pathname.startsWith(ptrn)
    );

    if (isUrlProtected) {
      showModal(MODAL_HOT_ADS_PAUSED_ID);
    } else {
      hideModal(MODAL_HOT_ADS_PAUSED_ID);
    }
  }, [account, location]);

  const hotLink = useMemo(() => generateHotLink(account), [account]);

  const handleCloseModalClick = useCallback(() => {
    hideModal(MODAL_HOT_ADS_PAUSED_ID);
  }, []);

  return (
    <Modal id={MODAL_HOT_ADS_PAUSED_ID} priority={2000} hideHeader canClose={false}>
      <div className="modal-hot-campaign">
        <Icon id={Icons.logo} className="icon_logo icon_logo--blue modal-hot-campaign__logo" />
        <h1 className="modal-hot-campaign__header">Your ads have been paused</h1>
        {account?.agency || account?.type === 'U' ? (
          <>
            <div className="modal-hot-campaign__content">
              AdultSearch has partnered up with <strong>HOT.com</strong> &mdash; an entertainment
              search engine that can bring you thousands of new clients.{' '}
            </div>
            <div className="modal-hot-campaign__content">
              In order to keep your Ads active and showing on the search page of AdultSearch, your
              account on <strong>HOT.com needs to be funded</strong> with the minimum monthly
              amount.
            </div>
          </>
        ) : (
          <>
            <div className="modal-hot-campaign__content">
              AdultSearch has partnered up with <strong>HOT.com</strong> &mdash; an entertainment
              search engine that can bring you thousands of new clients.{' '}
            </div>
            <div className="modal-hot-campaign__content">
              In order to keep your Ads active and showing on he search page of AdultSearch, please{' '}
              <strong>create a paid Image Promo on HOT.com and fund</strong> your account there with
              the minimum monthly amount.
            </div>
          </>
        )}
        <div className="modal-hot-campaign__content">
          If you do not fund your account on <strong>HOT.com</strong> within the next 48 hours, your
          ad will be removed from Adultsearch.com
        </div>
        <a className="btn primary-submit-btn" href={hotLink}>
          Proceed to HOT.com
        </a>
        <Link className="btn m-t-20" to={Page.MyAds} onClick={handleCloseModalClick}>
          Return to My Ads
        </Link>
      </div>
    </Modal>
  );
};

export default ModalHotAdsPaused;
