import {Page} from '~/utils/getLink';

export const MODAL_HOT_CAMPAIGN_ID = 'modal-hot-campaing';
export const MODAL_HOT_ADS_PAUSED_ID = 'modal-hot-ads-paused';

export const HOT_PROTECTED_PAGES = [
  Page.Payments,
  Page.ChangeEmail,
  Page.ChangePassword,
  Page.ChangeSettings,
  Page.ChangeUsername,
  Page.ChatSettings,
  Page.ConfirmEmail,
  Page.ChangeToAgency,
  Page.UnverifiedInfo,
  Page.Chat,
];
